import React from "react";
import { motion } from "framer-motion";
import { FaTwitter } from "react-icons/fa"; // Importación del ícono de Twitter
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import "./About.css";
import profileImage from "../../assets/images/yo.jpg";
import victoriaImage from "../../assets/images/victoria.jpeg";
import claudioImage from "../../assets/images/claudio.jpeg";

const About = () => {
  const navigate = useNavigate(); // Define navigate para la redirección

  const teamMembers = [
    {
      name: "Victoria",
      role: "AI Specialist",
      description:
        "Victoria handles the heavy lifting in an awesome way, constantly pushing the boundaries of what's possible on softwware development with ai asistance.",
      image: victoriaImage,
    },
    {
      name: "Claudio",
      role: "Blockchain Expert",
      description:
        "Claudio is fantastic for UI and our blockchain guru, ensuring our crypto solutions are cutting-edge and secure. He is on another level",
      image: claudioImage,
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const titleAnimation = {
    initial: { y: -50, opacity: 0 },
    whileInView: { y: 0, opacity: 1 },
    viewport: { once: false, amount: 0.8 },
    transition: { duration: 0.8, type: "spring", stiffness: 100 },
  };

  // Función para manejar el clic en el botón
  const handleContactClick = () => {
    navigate("/contact"); // Redirige a la página de contacto
  };

  return (
    <motion.div
      className="bittech-about"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h1 className="bittech-about__title" {...titleAnimation}>
        About Us
      </motion.h1>

      <motion.div className="bittech-about__founder" variants={itemVariants}>
        <img
          src={profileImage}
          alt="Antoine Ríos"
          className="bittech-about__founder-image"
        />
        <div className="bittech-about__founder-content">
          <motion.h2 {...titleAnimation}>Antoine Ríos</motion.h2>
          <motion.h3
            {...titleAnimation}
            className="custom-title-color blue-text"
          >
            Founder & Director
          </motion.h3>

          <p>
            Hey there! I'm the mind behind Bittech Network, your indie hub for
            all things tech and innovation. As a passionate enthusiast of
            software development, I love working on full stack projects, and
            I've always been drawn to the cutting edge of technology.
          </p>
          <p>
            Bittech Network is my indie playground for pushing the boundaries of
            what's possible in the digital realm. Here, I blend my passion for
            software development with my fascination for AI and blockchain
            technology.
          </p>

          <div className="bittech-about__social-links">
            <a
              href="https://twitter.com/antoine_bit"
              target="_blank"
              rel="noopener noreferrer"
              className="bittech-about__social-link"
            >
              <FaTwitter /> Follow me on X
            </a>
          </div>
        </div>
      </motion.div>

      <motion.h2 className="bittech-about__team-title" {...titleAnimation}>
        Meet Our AI Team
      </motion.h2>

      <motion.div className="bittech-about__team" variants={containerVariants}>
        {teamMembers.map((member, index) => (
          <motion.div
            key={index}
            className="bittech-about__team-member"
            variants={itemVariants}
          >
            <img
              src={member.image}
              alt={member.name}
              className="bittech-about__team-image"
            />
            <motion.h3 {...titleAnimation}>{member.name}</motion.h3>
            <motion.h4 {...titleAnimation}>{member.role}</motion.h4>
            <p>{member.description}</p>
          </motion.div>
        ))}
      </motion.div>

      <motion.div className="bittech-about__cta" variants={itemVariants}>
        <h2>Join Us on Our Journey</h2>
        <p>
          At Bittech Network, we're always pushing the boundaries of what's
          possible. Whether it's through AI, blockchain, or the next big thing
          in tech, we're here to innovate and create. Want to be part of our
          story?
        </p>
        <button
          className="bittech-about__cta-button"
          onClick={handleContactClick} // Maneja el clic para redirigir a la página de contacto
        >
          Get in Touch
        </button>
      </motion.div>
    </motion.div>
  );
};

export default About;
