import React, { memo } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import {
  FaRobot,
  FaBitcoin,
  FaPalette,
  FaCode,
  FaLightbulb,
  FaRocket,
  FaTwitter,
} from "react-icons/fa";
import "./Home.css";
import akibaImage from "../../assets/images/akiba.4.jpeg";

const Home = () => {
  const { scrollYProgress } = useScroll();
  const yBg = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const titleAnimation = {
    initial: { y: -50, opacity: 0 },
    whileInView: { y: 0, opacity: 1 },
    viewport: { once: false, amount: 0.8 },
    transition: { duration: 0.8, type: "spring", stiffness: 100 },
  };

  return (
    <motion.div
      className="bittech-home"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div className="bittech-home__background" style={{ y: yBg }} />

      <div className="flare flare-1"></div>
      <div className="flare flare-2"></div>
      <div className="flare flare-3"></div>

      <HeroSection titleAnimation={titleAnimation} itemVariants={itemVariants} />
      <WhoWeAreSection titleAnimation={titleAnimation} itemVariants={itemVariants} containerVariants={containerVariants} />
      <ProjectsSection titleAnimation={titleAnimation} itemVariants={itemVariants} containerVariants={containerVariants} />
      <ServicesSection titleAnimation={titleAnimation} itemVariants={itemVariants} containerVariants={containerVariants} />
      <CTASection titleAnimation={titleAnimation} itemVariants={itemVariants} containerVariants={containerVariants} />
    </motion.div>
  );
};

const HeroSection = memo(({ titleAnimation, itemVariants }) => (
  <motion.section className="bittech-home__hero">
    <motion.div
      className="bittech-home__hero-content"
      variants={itemVariants}
    >
      <motion.h1 className="bittech-home__hero-title" {...titleAnimation}>
        Bittech Network
        <motion.span
          className="bittech-home__hero-subtitle"
          {...titleAnimation}
        >
          Tech is fun, fun is good
        </motion.span>
      </motion.h1>
      <motion.p
        className="bittech-home__hero-description"
        variants={itemVariants}
      >
        Crafting cutting-edge products and solutions for the digital age
      </motion.p>
      
    </motion.div>
  </motion.section>
));

const WhoWeAreSection = memo(({ titleAnimation, itemVariants, containerVariants }) => (
  <motion.section
    className="bittech-home__who-we-are"
    variants={containerVariants}
  >
    <motion.h2 className="bittech-home__section-title" {...titleAnimation}>
      Who We Are
    </motion.h2>
    <motion.p
      className="bittech-home__section-description modern-text"
      variants={itemVariants}
    >
      At Bittech Network, we are humans and AI´s and the forefront. Our mission is
      to bridge the gap between cutting-edge technology and practical,
      user-friendly solutions. We specialize in creating AI-powered
      applications, blockchain solutions, and advanced web services that
      push the boundaries of what's possible in the digital realm.
    </motion.p>
    <motion.p
      className="bittech-home__section-description modern-text"
      variants={itemVariants}
    >
      Our goal is to empower small and big businesses and individuals with
      tools that not only meet the demands of today's fast-paced digital
      world but also anticipate the needs of tomorrow. We believe in the
      transformative power of technology and its ability to create positive
      change in how we live, work, and interact with the world around us.
    </motion.p>
  </motion.section>
));

const ProjectsSection = memo(({ titleAnimation, itemVariants, containerVariants }) => (
  <motion.section
    className="bittech-home__projects"
    variants={containerVariants}
  >
    <motion.h2 className="bittech-home__section-title" {...titleAnimation}>
      Our Projects
    </motion.h2>
    <motion.p
      className="bittech-home__section-description modern-text"
      variants={itemVariants}
    >
      At Bittech Network, we're not just dreaming about the future - we're
      building it. Check out our groundbreaking projects:
    </motion.p>
    <motion.div
      className="bittech-home__project-grid"
      variants={containerVariants}
    >
      {[
        {
          icon: FaRobot,
          title: "MystiChat.ai",
          description:
            "An AI-powered platform offering mystical insights and personalized guidance through advanced language processing and predictive analytics.",
        },
        {
          icon: FaBitcoin,
          title: "Block Buddies",
          description:
            "A gamified educational platform that makes learning about cryptocurrency and blockchain technology fun, interactive, and accessible to everyone.",
        },
      ].map((project, index) => (
        <motion.div
          key={index}
          className="bittech-home__project-item"
          variants={itemVariants}
          whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
        >
          <div className="bittech-home__project-icon-wrapper">
            <project.icon className="bittech-home__project-icon" />
          </div>
          <motion.h3
            className="bittech-home__project-title"
            {...titleAnimation}
          >
            {project.title}
          </motion.h3>
          <p className="bittech-home__project-description modern-text">
            {project.description}
          </p>
        </motion.div>
      ))}
    </motion.div>
  </motion.section>
));

const ServicesSection = memo(({ titleAnimation, itemVariants, containerVariants }) => (
  <motion.section
    className="bittech-home__services"
    variants={containerVariants}
  >
    <motion.h2 className="bittech-home__section-title" {...titleAnimation}>
      Our Services
    </motion.h2>
    <motion.p
      className="bittech-home__section-description modern-text"
      variants={itemVariants}
    >
      Leveraging our expertise in cutting-edge technologies, we offer a
      comprehensive suite of services designed to propel your business into
      the future. Our team of skilled professionals is dedicated to
      delivering innovative solutions tailored to your unique needs.
    </motion.p>
    <motion.div
      className="bittech-home__service-grid"
      variants={containerVariants}
    >
      {[
        {
          icon: FaPalette,
          title: "Web Design",
          description:
            "Creating stunning, responsive websites that captivate and engage your audience.",
        },
        {
          icon: FaCode,
          title: "Web Apps",
          description:
            "Developing powerful, scalable applications that drive business growth and efficiency.",
        },
        {
          icon: FaBitcoin,
          title: "Crypto Solutions",
          description:
            "Integrating blockchain technology to enhance security and transparency in your operations.",
        },
        {
          icon: FaLightbulb,
          title: "Tech Consultancy",
          description:
            "Providing expert guidance to navigate the complex landscape of emerging technologies.",
        },
        {
          icon: FaRocket,
          title: "Rapid Deployment",
          description:
            "Ensuring quick and efficient launches of your digital products and services.",
        },
        {
          icon: FaTwitter,
          title: "AI Bots for X",
          description:
            "Boost your X presence with our AI-powered marketing solutions.",
        },
      ].map((service, index) => (
        <motion.div
          key={index}
          className="bittech-home__service-item"
          variants={itemVariants}
          whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
        >
          <div className="bittech-home__service-icon-wrapper">
            <service.icon className="bittech-home__service-icon" />
          </div>
          <motion.h3
            className="bittech-home__service-title"
            {...titleAnimation}
          >
            {service.title}
          </motion.h3>
          <p className="bittech-home__service-description modern-text">
            {service.description}
          </p>
        </motion.div>
      ))}
    </motion.div>
  </motion.section>
));

const CTASection = memo(({ titleAnimation, itemVariants, containerVariants }) => (
  <motion.section
    className="bittech-home__cta"
    variants={containerVariants}
  >
    <motion.h2 className="bittech-home__section-title" {...titleAnimation}>
      Ready to Innovate?
    </motion.h2>
    <div>
      <img src={akibaImage} alt="Akiba" className="akiba-image" />
    </div>
    <motion.p
      className="bittech-home__cta-description modern-text"
      variants={itemVariants}
    >
      Whether you're here for our groundbreaking projects or our
      comprehensive services, we're ready to turn your vision into reality.
      Let's embark on a journey of innovation together and shape the future
      of technology.
    </motion.p>
    <Link to="/contact" className="bittech-home__cta-button">
      Start Your Journey
    </Link>
  </motion.section>
));

export default Home;

