import React, { useEffect, memo } from "react";
import { motion } from "framer-motion";
import { FaBitcoin, FaEthereum, FaDog, FaBolt } from 'react-icons/fa';
import "./Projects.css";

// Importaciones de imágenes
import mystichatImage from "../../assets/images/skull1.png";
import mystichatDash from "../../assets/images/dash.png";
import theLoversImage from "../../assets/images/the_lovers.png";
import theStarImage from "../../assets/images/the_star.png";
import theMoonImage from "../../assets/images/the_moon.png";
import mystiExtraImage from "../../assets/images/mysti3.jpeg";
import blockBuddiesLogo from "../../assets/images/logook.svg";
import blockBuddiesRobo from "../../assets/images/robo4.svg";
import blockBuddiesLottery from "../../assets/images/logoloteria2.svg";
import blockBuddiesDash from "../../assets/images/dash2.jpeg";

const titleAnimation = {
  initial: { y: -50, opacity: 0 },
  whileInView: { y: 0, opacity: 1 },
  viewport: { once: false, amount: 0.8 },
  transition: { duration: 0.8, type: "spring", stiffness: 100 },
};

const Projects = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const projectsData = [
    {
      title: "MystiChat.ai",
      description: "Where AI-Generated Art Meets Mystical Insights",
      status: "⚠️ Currently Undergoing Updates - Coming Back Online Soon!",
      details: (
        <>
          <p>
            MystiChat is an innovative web application that uniquely blends AI-generated art with mystical tarot readings. Our state-of-the-art generative AI model crafts visually stunning artworks, each piece uniquely inspired by the individual tarot readings provided by the platform.
          </p>
          <p>MystiChat features two specialized AI models:</p>
          <p>
            <strong className="mystichat-strong">Tarot Room:</strong> This model is specifically trained to work with tarot, providing accurate and insightful readings tailored to the individual user's queries. It offers a deep, esoteric experience, perfect for those seeking guidance through the mystical art of tarot.
          </p>
          <p>
            <strong className="mystichat-strong">Dream Room:</strong> This model uses generative AI to provide interpretations of dreams and respond to any other esoteric inquiries you might have. The Dream Room creates an immersive experience with generative AI, delivering profound and personalized insights. For the best results, we recommend using the laptop version of the platform, which offers enhanced capabilities and a more immersive experience.
          </p>
          <p>
            We've embraced the future of digital transactions with our latest update: Enter the Lightning Network! This groundbreaking technology for Bitcoin micro-transactions allows you to purchase MystiCoins (credits) securely and in just 2 seconds.
          </p>
          <p>
            MystiChat is proud to be the #1 web app utilizing this cutting-edge payment technology. We now exclusively accept Bitcoin through the Lightning Network, offering unparalleled speed, security, and efficiency in your transactions.
          </p>
          <div className="bittech-projects__crypto-icons">
            <div><FaBitcoin /> <FaBolt /> Bitcoin via Lightning Network</div>
          </div>
        </>
      ),
      mainImage: mystichatImage,
      dashboardImage: mystichatDash,
      tarotImages: [theLoversImage, theStarImage, theMoonImage],
      extraImage: mystiExtraImage,
      link: "https://www.mystichat.ai",
      dashboardDescription: (
        <p>
          Our intuitive dashboard features elegant widgets including the current moon phase, CET date, inspiring quotes that update each session, access to the Tarot Room and Dream Room, as well as sections for updates and promotions.
        </p>
      ),
      updateInfo: (
        <p>
          Exciting news! In our upcoming update, MystiChat will feature a brand new shop offering tarot decks and fun esoteric accessories. Enhance your mystical experience with our carefully curated collection of spiritual items!
        </p>
      ),
      collaborationInfo: (
        <p>
          These beautiful tarot cards were created specifically for this project in collaboration with Venezuelan plastic artist Gliterry.
        </p>
      ),
    },
    {
      title: "Block Buddies",
      description: "An Integrated AI and Crypto Ecosystem",
      status: `🎉 Exciting News: Web App Launching Soon! Launch planned by autumn 2024. Follow us on X for more updates @blockbuddies1.`,
      details: (
        <>
          <p>
            Block Buddies is an innovative ecosystem that combines artificial intelligence and the cryptocurrency sector. We offer advanced live data analysis tools for users, aiming to be the ultimate platform that rewards user participation through an awesome lottery section.
          </p>
          <p>Our platform features two main sections:</p>
          <p>
            <strong className="block-buddies-strong-red">The Boiler Room:</strong> Our AI-powered data analysis center provides unparalleled market insights. It generates comprehensive summaries of market conditions, including price targets, support and resistance levels, and potential price predictions. All of this is based on historical and current data, opening prices, historical candles, candle pattern recognition, indicator analysis, and more.
          </p>
          <p>
            <strong className="block-buddies-strong-blue">Our Games and Lotteries:</strong> Designed to be the ultimate reward-sharing platform where nobody leaves empty-handed. We offer three exciting games:
          </p>
          <div className="bittech-projects__games-list">
            <div><strong className="block-buddies-strong-yellow">Crypto Price Prediction</strong></div>
            <div><strong className="block-buddies-strong-yellow">Dog Derby</strong></div>
            <div><strong className="block-buddies-strong-yellow">Satoshi 30</strong></div>
          </div>
          <p>
            We embrace DeFi principles in our platform. Ticket purchases and subscriptions can be made using Bitcoin, Ethereum, and Dogecoin.
          </p>
          <div className="bittech-projects__crypto-icons">
            <div><FaBitcoin /> </div>
            <div><FaEthereum /> </div>
            <div><FaDog /> </div>
          </div>
        </>
      ),
      mainImage: blockBuddiesLogo,
      dashboardImage: blockBuddiesDash,
      boilerRoomImage: blockBuddiesRobo,
      lotteryImage: blockBuddiesLottery,
      link: "",
      boilerRoomDescription: (
        <p>
          Our AI, Boiler Room, analyzes over 1000 coins through an advanced data analysis process. We feed it with comprehensive market data to provide unparalleled insights into the crypto market.
        </p>
      ),
      lotteryDescription: (
        <p>
          Participate in our exciting DEFI crypto lottery! Block Buddies offers a unique opportunity to win big while engaging with the crypto community.
        </p>
      ),
      dashboardDescription: (
        <p>
          Our comprehensive dashboard provides real-time crypto market data, AI-driven insights, and easy access to our unique DEFI lottery system. Stay informed and engaged with the crypto world through our user-friendly interface.
        </p>
      ),
    },
  ];

  return (
    <motion.div
      className="bittech-projects"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1 className="bittech-projects__title" {...titleAnimation}>
        Our Projects
      </motion.h1>
      {projectsData.map((project, index) => (
        <ProjectSection key={index} project={project} index={index} />
      ))}
      <div className="bittech-more">
        <h2 className="bittech-more__title">More cool products to come</h2>
      </div>
    </motion.div>
  );
};

const ProjectSection = memo(({ project, index }) => (
  <motion.section
    className="bittech-projects__section"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
  >
    <motion.h2 className="project-title" {...titleAnimation}>
      {project.title}
    </motion.h2>
    <div className="bittech-projects__grid">
      <div className="bittech-projects__main-content">
        <motion.img
          src={project.mainImage}
          alt={project.title}
          className="bittech-projects__main-image"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        />
        <div className="bittech-projects__text">
          <p className="bittech-projects__description">{project.description}</p>
          <p className="bittech-projects__status">{project.status}</p>
          <div className="bittech-projects__details">{project.details}</div>
          {project.title === "MystiChat.ai" && (
            <motion.a
              href={project.link}
              className="bittech-projects__link"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Learn More
            </motion.a>
          )}
        </div>
      </div>
      <div className="bittech-projects__dashboard">
        <img
          src={project.dashboardImage}
          alt={`${project.title} Dashboard`}
          className="bittech-projects__dashboard-image"
        />
        <p className="bittech-projects__dashboard-description">
          {project.dashboardDescription}
        </p>
      </div>
      {project.title === "MystiChat.ai" && (
        <>
          <div className="bittech-projects__tarot-cards">
            {project.tarotImages.map((image, i) => (
              <img
                key={i}
                src={image}
                alt={`Tarot Card ${i + 1}`}
                className="bittech-projects__tarot-card"
              />
            ))}
          </div>
          <div className="bittech-projects__collaboration-info">
            <p>{project.collaborationInfo}</p>
          </div>
          <div className="bittech-projects__extra-image">
            <img src={project.extraImage} alt="MystiChat Extra" />
            <div className="bittech-projects__update-info">
              <h3>Coming Soon: MystiShop!</h3>
              <p>{project.updateInfo}</p>
            </div>
          </div>
        </>
      )}
      {project.title === "Block Buddies" && (
        <>
          <div className="bittech-projects__boiler-room">
            <img
              src={project.boilerRoomImage}
              alt="Boiler Room"
              className="bittech-projects__boiler-room-image"
            />
            <p className="bittech-projects__boiler-room-description">
              {project.boilerRoomDescription}
            </p>
          </div>
          <div className="bittech-projects__lottery">
            <img
              src={project.lotteryImage}
              alt="Lottery"
              className="bittech-projects__lottery-image"
            />
            <p className="bittech-projects__lottery-description">
              {project.lotteryDescription}
            </p>
          </div>
          <div className="bittech-projects__twitter-embed">
            <blockquote className="twitter-tweet" data-theme="dark">
              <p lang="en" dir="ltr">
                🚀✨ Hey{" "}
                <a href="https://twitter.com/hashtag/Crypto?src=hash&amp;ref_src=twsrc%5Etfw">
                  #Crypto
                </a>{" "}
                fam! BTC is currently at $63,901.17 📈 with a 24h volume of
                $20.29B and a slight dip of -0.71%. Support at $62k &amp;
                resistance at $65k🔒💪 What's your prediction? 🤔 Let's chat!
                Follow us for more updates!{" "}
                <a href="https://twitter.com/hashtag/BTC?src=hash&amp;ref_src=twsrc%5Etfw">
                  #BTC
                </a>{" "}
                <a href="https://twitter.com/hashtag/AI?src=hash&amp;ref_src=twsrc%5Etfw">
                  #AI
                </a>{" "}
                🌟
              </p>
              &mdash; Block Buddies AI (@BlockBuddies1){" "}
              <a href="https://twitter.com/BlockBuddies1/status/1827647306880921933?ref_src=twsrc%5Etfw">
                August 25, 2024
              </a>
            </blockquote>
            <p className="bittech-projects__ai-bot-description">
              We have a simplified yet powerful version of the Boiler Room AI
              bot analyzing the BTC, ETH, and DOGE markets, as well as indices,
              every hour and posting updates on X. Follow us @BlockBuddies1 for
              real-time market insights!
            </p>
          </div>
        </>
      )}
    </div>
  </motion.section>
));

export default Projects;

  